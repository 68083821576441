import React from 'react';
import { Helmet } from 'react-helmet';
import '../PrivacyPolicy/PrivacyPolicy.css'


const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Homee Onetouch Platform</title>
        <link rel="canonical" href="https://homeeonetouchplatform.com/PrivacyPolicy" />
      </Helmet>
      <div className={'our_team_bg'}>
        <div className={'pad'}>
          <div className={'our_team_head'}>
            <h5>Privacy Policy</h5>
            {/* <p>Home / <span>Privacy Policy</span></p> */}
          </div>
        </div>
      </div>


      <div className={'pad'}>
        <div className={'row mt-5'}>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="terms-condition-content">
                  <p>This legal agreement is an electronic record in terms of the <b>Indian Information Technology Act, 2000</b> and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the <b>Indian Information Technology Act, 2000.</b> This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                  <p>This legal document is published in accordance with the provisions of <b>Rule 3 (1)</b> of the <b>Indian Information Technology (Intermediaries guidelines) Rules, 2011</b> and <b>Rule 4</b> of the <b>Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 </b>amended through <b>Information Technology Amendment Act, 2008</b> that require publishing the Terms of Use and practices for access and usage of any functional Platform.</p>
                  <p>This Platform is created and operated by <b>M/s Firstgen Ecom India Pvt Limited.</b> The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across on this Platform. This privacy policy contains information about the Platform<b> “HOMEE” (“Platform”).</b></p>
                  <ul>
                    <li>For the purpose of these Privacy Policy <b>(“Privacy Policy”),</b> wherever the context so requires, “We”, “Company”, “Our”, and “Us” shall mean and refer to the Platform.</li>
                    <li>“You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals including cooks and end-users who shall be Users of this Platform provided by us and who are competent to enter into binding contracts, as per Indian laws. </li>
                    <li>“Third Parties” refer to any website, platform or individual apart from the Users and the creator of this Platform</li>
                  </ul>
                  <p>To provide You with Our uninterrupted use of the Platform, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.</p>
                  <p><strong>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at --- info@homeeplatform.com.</strong></p>
                  <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>
                  <h4>INFORMATION WE COLLECT</h4>
                  <p>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:</p>
                  <ul>
                    <li>Registration data for user application such as full name,  email ID, phone number and shipping address (using google map maps and geocoding). For <b>Service Providers</b> application, registration data such as Email, Mobile Number, Name, <b>Photo ID</b> Shipping Address (using google map maps and geocoding) and Bank details.</li>
                    <li>Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform;</li>
                    <li>For the registered users, we require the additional information of their device using our platform such as GPS, Camera and Storage. For the For <b>Service Provider</b> application, we require the information such as, but not limited to GPS, Camera, Storage and Contacts.</li>
                  </ul>
                  <p>This privacy policy also applies to data we collect from users who are not registered as members of this Platform, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Platform. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:</p>
                  <ul>
                    <li>To enable the provision of services opted for by you;</li>
                    <li>To enable the viewing of content in your interest;</li>
                    <li>To communicate the necessary account and service-related information from time to time;</li>
                    <li>To allow you to receive quality customer care services and data Collection;</li>
                    <li>To comply with applicable laws, rules and regulations;</li>
                  </ul>
                  <p>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the Platform.</p>
                  <p>Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</p>
                  <h4>OUR DISCLOSURE OF YOUR INFORMATION</h4>
                  <p>We may host surveys for survey creators for our Platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</p>
                  <p>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</p>
                  <p>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.</p>
                  <p>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:</p>
                  <ul>
                    <li>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</li>
                    <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.</li>
                  </ul>
                  <h4>CONTACT US</h4>
                  <p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to <b>
                    info@homeeplatform.com</b></p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div >
  )
}

export default PrivacyPolicy
