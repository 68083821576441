import React from 'react';
import { Helmet } from 'react-helmet';
import '../Terms&Conditions/Terms&cond.css'


const TermsConditions = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions - Homee Onetouch Platform</title>
        <link rel="canonical" href="https://homeeonetouchplatform.com/TermsConditons" />
      </Helmet>
      <div className={'our_team_bg'}>
        <div className={'pad'}>
          <div className={'our_team_head'}>
            <h5>Terms & Conditions</h5>
            <p>Home / <span>Terms & Conditions</span></p>
          </div>
        </div>
      </div>


      <div className={'pad'}>
        <div className={'row mt-5'}>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="terms-condition-content">             
                  <h4>GENERAL TERMS</h4>
                  <p>The headings of each section in these Terms of Use are only for the purpose of organizing the various provisions under these Terms of Use in an orderly manner and shall be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall have legal or contractual value on your usage of the Platform.</p>
                  <p>The use of this Platform is solely governed by these Terms of Use, Privacy Policy that may be uploaded on the Platform and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a user continue to access and use this Platform, you are agreeing to comply with and be bound by the following Terms of Use and Privacy Policy.</p>
                  <p>You expressly agree and acknowledge that these Terms of Use and Privacy Policy are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.</p>
                  <p>You unequivocally agree that these Terms of Use and the Privacy Policy constitute a legally binding agreement between us, and that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the Platform, and that the same shall be deemed to be incorporated into these Terms of Use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of Use and the Privacy Policy binding on you and that your act of visiting any part of the Platform constitutes your full and final acceptance of these Terms of Use and the Privacy Policy.</p>
                  <p>We reserve the sole and exclusive right to amend or modify these Terms of Use without any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, You must stop using the Platform at once. Your continued use of the Platform will signify your acceptance of the changed terms.</p>
                  <h4>REGISTRATION</h4>
                  <p>Registration is optional for our Platform. Users can choose to register on the Platform, or browse and buy without registration.</p>
                  <h4>ELIGIBILITY</h4>
                  <p>You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind themselves to these Terms of Use following the Law. However, if you are a minor using this Platform, you may do so with the consent of your legal guardian. All Acts of minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian.</p>
                  <p>You further represent that you will comply with these Terms of Use and all applicable local, state, national and international laws, rules and regulations.</p>
                  <p>You shall not use the Platform if you are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force.</p>
                  <h4>PAYMENT TERMS AND GATEWAY</h4>
                  <p>For making all payments for purchases on the Platform, you shall be required to make payment for which you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.</p>
                  <h5>Payment terms follow the below guidelines:</h5>
                  <p>Payment if made once the end User has placed and order. The payment will be processed once the cook accepts the order. In a case, where the cook rejects the order the payment will be returned back to the end Use.</p>
                  <p>Cooks registered on the Platform will get a SIX MONTHS free subscription.</p>
                  <p>In case the cook wants to continue with the subscription a commission rate of 7% to 12% based on the subscription pack will be charged.</p>
                  <h4>CONTENT</h4>
                  <p>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, <b>‘Content’</b>), is generated/provided or based on information provided by the users or third parties and We has no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Platform.</p>
                  <p>All the Content displayed on the Platform is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner.</p>
                  <p>You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the Platform and whilst feedback and comments by You can be made via the Platform, we bear no liability whatsoever for any feedback or comments made by the other Users or made in respect of any of the content on the Platform. Further, the Platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Platform or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.</p>
                  <p>You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the Platform. You shall not copy, adapt, and modify any content without written permission from Us.</p>
                  <h4>INDEMNITY</h4>
                  <p>You agree to indemnify, defend and hold harmless the Us, and our respective directors, officers, employees and agents (collectively, <b>"Parties"</b>), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of Use. Further, You <b>agree</b> to hold the Us harmless against any claims made by any third party due to, or arising out of, or in connection with:</p>
                  <ul>
                    <li>Your use of the Platform,</li>
                    <li>Your violation of these Terms of Use;</li>
                    <li>Your violation of any rights of another;</li>
                    <li>Your alleged improper conduct according to these Terms of use;</li>
                    <li>Your conduct in connection with the Platform;</li>
                  </ul>
                  <p>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to settle with any party without consent from Us.</p>
                  <p>In no event shall we be liable to compensate You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the Your use of or access to the Platform and/or the Services or materials contained therein.</p>
                  <h4>LIMITATION OF LIABILITY</h4>
                  <p>We are not responsible for any consequences arising out of the following events:</p>
                  <ul>
                    <li>If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</li>
                    <li>If you have fed incorrect information or data or for any deletion of data;</li>
                    <li>If there is an undue delay or inability to communicate through email;</li>
                    <li>If there is any deficiency or defect in the Services managed by Us;</li>
                    <li>If there is a failure in the functioning of any other service provided by Us.</li>
                  </ul>
                  <p>The Platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the Platform or any service availed of by Us through the Platform. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. We will not be liable to you for the unavailability or failure of the Platform.</p>
                  <p>You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Use by reference.</p>
                  <p>The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Platform and which is incurred by you in connection with the Platform, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Use.</p>
                  <p>To the fullest extent permitted by law, We shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Platform.</p>
                  <h4>PERMISSION FROM DEVICE</h4>
                  <p>Platform requires the permission from the device of the following features:</p>
                  <ul>
                    <li>For User Platform: GPS, Camera and Storage.</li>
                    <li>For Cook Platform: GPS, Camera, Storage and Contacts.</li>
                  </ul>
                  <h4>COMMUNICATION</h4>
                  <p>By using this Platform and providing Your identity and contact information to Us through the Platform, You agree and consent to receive calls, e-mails or SMS from Us and/or any of its representatives at any time.</p>
                  <p>You can report to <b>info@homeeplatform.com”</b> if you find any discrepancy with regard to Platform or content-related information and we will take necessary action after an investigation.  The response with resolution (if any issues found) shall be dependent on the time is taken for investigation.</p>
                  <p>You expressly agree that notwithstanding anything contained herein above, it may be contacted by Us relating to any services availed by You on the Platform or anything pursuant thereto and You agree to indemnify Us from any harassment claims. It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy.</p>
                  <h4>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</h4>
                  <p>You agree and acknowledges that you are an user of this Platform and you:</p>
                  <ul>
                    <li>Agree to provide genuine credentials during the process whenever required on the Platform. You shall not use a fictitious identity to register. We are not liable if you have provided incorrect information.</li>
                    <li>Agree to ensure the Name, Email address, Address, Mobile number and any such other information that may be provided during account registration is valid at all times and shall keep your information accurate and up-to-date.</li>
                    <li>You agree to our use of Dunzo as delivery partner and sharing of your information relating to latitude, longitude, street address, name and phone number using API.</li>
                    <li>You agree that You are solely responsible for maintaining the confidentiality of your account password. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</li>
                    <li>The Platform does not bear any liability towards the quality of the product. The Platform simply hosts the different Vendors who lists their products on the Platform.</li>
                    <li>Authorize the Platform to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimization of User-related options and Services.</li>
                    <li>Understand and agree that, to the fullest extent permissible by law, the Platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Platform or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</li>
                    <li>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Platform. Any such use/limited use of the Platform will only be allowed with the prior express written permission.</li>
                    <li>Agree not to access (or attempt to access) the Platform and/or the materials or Services by any means other than through the interface provided by the Platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the Platform. We disclaim any liabilities arising concerning such offensive content on the Platform.</li>
                    <li>Expressly agree and acknowledge that the Content generated by the Users and displayed on the Platform is not owned by Us and that We are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which We may then remove from the Platform, at our sole discretion.</li>
                  </ul>

                  <h4>SUSPENSION OF USER ACCESS AND ACTIVITY</h4>
                  <p>Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the Platform, and/or refuse to the usage of the Platform, without being required to provide you with notice or cause:</p>
                  <ul>
                    <li>If you are in breach of any of these Terms of Use or the Privacy Policy;</li>
                    <li>If you have provided wrong, inaccurate, incomplete or incorrect information;</li>
                    <li>If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion.</li>
                  </ul>
                  <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                  <p>Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Use that shall be available on the Platform. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the Platform or such other third party and other distinctive brand features of the Platform are the property of the Platform or the respective copyright or trademark owner. Furthermore, concerning the Platform, we shall be the exclusive owner of all the designs, graphics and the like, related to the Platform.</p>
                  <p>You shall not use any of the intellectual property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective users of the Platform, or that in any manner disparages or discredits the Platform, to be determined in the sole discretion.</p>
                  <p>You are aware all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators. You are aware that we merely provide the Platform through which You can communicate with other users and the Platform does not own any of the intellectual property relating to the independent content displayed on the Platform, apart from created graphics and specified content.</p>
                  <p>You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Use and/or Privacy Policy.</p>
                  <h4>DISCLAIMER OF WARRANTIES AND LIABILITIES</h4>
                  <p>You further agree and undertake that you are accessing the Platform at your sole risk and are that you are using the best and prudent judgment before availing of any features on the Platform or accessing/using any information displayed thereon.</p>
                  <p>You agree that any kind of information, resources, activities, recommendations obtained/availed from the Platform, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</p>
                  <p>We do not guarantee that the features and content contained in the Platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and You hereby expressly accepts any associated risks involved with your use of the Platform.</p>
                  <p>It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</p>
                  <h4>DISPUTE RESOLUTION AND JURISDICTION</h4>
                  <p>It is expressly agreed to by you hereto that the formation, interpretation, and performance of these Terms of Use and any disputes arising therefrom will be resolved through Arbitration. It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</p>
                  <p><strong>Arbitration:</strong> In the event that the Parties are unable to amicably resolve a dispute, the said dispute will be referred to arbitration by a sole arbitrator to be appointed by us, and the award passed by such sole arbitrator will be valid and binding on all parties. All cost of the Arbitration shall be borne solely in accordance with the Arbitrators award. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of <b>chennai, India. </b>
                    You expressly agree that the Terms of Use, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India.</p>
                  <h4>MISCELLANEOUS PROVISIONS</h4>
                  <p><strong>Entire Agreement:</strong> These Terms of Use, read with the Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</p>
                  <p><strong>Waiver: </strong> The failure at any time to require performance of any provision of these Terms of Use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Use.</p>
                  <p><strong>Severability:</strong> If any provision/clause of these Terms of Use is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Use shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of Use shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</p>
                  <p><strong>Contact Us:</strong> If you have any questions about these Terms of Use, the practices of the Platform, or your experience, you can contact us by emailing at <b>info@homeeplatform.com</b> .</p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default TermsConditions
