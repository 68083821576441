import React from 'react';
import { Helmet } from 'react-helmet';
import '../Refund & Cancellation/RefundCancellation.css'


const RefundCancellation = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cancellation and Refund Policy - Homee Onetouch Platform</title>
        <link rel="canonical" href="https://homeeonetouchplatform.com/RefundCancellation" />
      </Helmet>
      <div className={'our_team_bg'}>
        <div className={'pad'}>
          <div className={'our_team_head'}>
            <h5>Refund & Cancellation</h5>
            <p>Home / <span>Refund & Cancellation</span></p>
          </div>
        </div>
      </div>


      <div className={'pad'}>
        <div className={'row mt-5'}>
          <div className={'col-lg-6'}>
            <p className={'pt-5'}> If you cancel your order within 60 seconds of payment, you will receive a full refund within 24 hours.<b>
              Once the order is prepared or delivered, refunds are not applicable.<br/>if refund is valid it can be credited to your original payment method with in 2 to 3 business days</b></p>
            <p>Thanks, and regards </p>
          </div>

          <div className={'col-lg-6'}>
            <h2>PAYMENT TERMS AND GATEWAY</h2>
            <p>For making all payments for purchases on the Platform, you shall be required to make payment for which you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.</p>

            <p> HOMEE PLATFORM PROVIDING THESE FACILITIES to make all the HOME Makers independent in their financial needs to the maximum extent
              possible by using their skill of cooking and presenting the foods to the users</p>
          </div>

        </div>

      </div>

    </div>
  )
}

export default RefundCancellation
