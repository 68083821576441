import React from 'react';
import '../shared/footer.css';
import Logowhite from '../asset/logowhite.png';
import { MdOutlineFastfood, MdOutlineDocumentScanner } from 'react-icons/md';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { FaFacebookF, FaLinkedinIn, FaYoutube, FaGooglePlay, FaApple, FaPhoneAlt } from 'react-icons/fa';
import { GrDocumentText } from 'react-icons/gr';
import Playstore from '../asset/playstore.png';
import Applestore from '../asset/applestore.png';
import Androidqr from '../asset/androidqr.png';
import Iosqr from '../asset/iosqr.png';
import { Button, Form } from 'react-bootstrap';
import ModalImage from 'react-modal-image';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/Scroller';

const Footer = () => {

    return (
        <div className={'footer_bg'}>
            <div className={'pad'}>
                <div className={'row justify-content-between py-5'}>
                    <div className={'col-xl-4 col-lg-6 col-md-6  at_homee'}>
                        <img src={Logowhite} />
                        <p className={'mt-4'}>
                            At HOMEE, we believe that everyone should have access to delicious and healthy meals,
                            no matter how busy their schedule .That's why we created an easy-to-use platform that
                            connects busy individuals with top-rated chefs and restaurants in their area.
                        </p>

                        <div className={'social_icons'}>
                            <p>
                                <a href='https://www.facebook.com/homeeotp'><span className={'mx-1'} ><FaFacebookF /></span>  </a>
                                <a href='https://www.instagram.com/homeeotp/'> <span className={'mx-1'}><BsInstagram /></span></a>
                                <a href='https://twitter.com/homeeotp'><span className={'mx-1'}><BsTwitter /></span></a>
                                <a href='https://in.linkedin.com/company/homeeotp'><span className={'mx-1'}><FaLinkedinIn /></span></a>
                                <a href='https://www.youtube.com/channel/UCDCqFAH3A3OXzOSxvTdLm4w'> <span className={'mx-1'}><FaYoutube /></span></a>
                                @homeeotp
                            </p>
                        </div>

                        <hr style={{ color: '#fff' }}></hr>

                        <div className={"address"}>
                            <div className={'row'}>
                                <div className={"col-lg-12"}>
                                    <h3 >Chennai</h3>
                                    <p>51, Second Main Rd, Gandhi Nagar, Adyar, Chennai, Tamil Nadu 600020</p>
                                </div>
                                <div className={"col-lg-12"}>
                                    <h3>Hyderabad</h3>
                                    <p>Shutter no 2,Plot no.93,Swasthik nagar near, Sahithi villas,puppalaguda,manikonda, Gandipet mantal, R.R dist. Hyderabad-500089, Telangana.</p>
                                </div>

                            </div>
                        </div>
                        <hr style={{ color: '#fff' }}></hr>

                        <div className={'homee_store'}>
                            <div className={'row'}>
                                <div className={'col-lg-12'}>
                                    <p>Download Our User App</p>
                                </div>
                                <div className={'col-lg-5 col-md-5 col-sm-6 col-6 mb-4'}>
                                    <a href='https://play.google.com/store/apps/details?id=com.homeeuser'> <img src={Playstore} width="100%" /> </a>
                                </div>
                                <div className={'col-lg-5 col-md-5 col-sm-6 col-6'}>
                                    <a href='https://apps.apple.com/in/app/homee/id1603826750'> <img src={Applestore} width="100%" /> </a>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className={'col-xl-4 col-lg-6 col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-md-6 col-sm-6 col-6 quick_links'}>
                                <h6>Quick Links</h6>
                                <ul>
                                    <Link to={'/'} onClick={scrollToTop}> <li><span><MdOutlineFastfood /></span> Home</li> </Link>
                                    <Link to={'/aboutus'} onClick={scrollToTop}> <li><span><MdOutlineFastfood /></span> About Us</li> </Link>
                                    <Link to={'/career'} onClick={scrollToTop}> <li><span><MdOutlineFastfood /></span> Career</li> </Link>
                                    <Link to={'/contactus'} onClick={scrollToTop}> <li><span><MdOutlineFastfood /></span> Contact Us</li> </Link>

                                </ul>
                            </div>
                            <div className={'col-lg-6 col-md-6 col-sm-6 col-6 quick_links'}>
                                <h6>Homee Policys</h6>
                                <ul>
                                    <Link to={'/TermsConditions'} onClick={scrollToTop}> <li><span><MdOutlineDocumentScanner /></span> Terms & Condition</li> </Link>
                                    <Link to={'/Privacypolicy'} onClick={scrollToTop}> <li><span><MdOutlineDocumentScanner /></span> Privacy Policy</li> </Link>
                                    <Link to={'/CaterersSaviour'} onClick={scrollToTop}> <li><span><MdOutlineDocumentScanner /></span> Caterers Saviour</li> </Link>
                                    <Link to={'/ChefAgreement'} onClick={scrollToTop}> <li><span><MdOutlineDocumentScanner /></span> Chef Agreement</li> </Link>
                                    <Link to={'/RefundCancellation'} onClick={scrollToTop}> <li><span><MdOutlineDocumentScanner /></span> Refund & Cancellation</li> </Link>


                                </ul>
                            </div>

                            <div className={'col-lg-12 '}>
                                <div className={'row d-flex align-items-center '}>
                                    <div className={'col-lg-4 col-md-4 qrcode_head'}>
                                        <h6>Cook Registration</h6>
                                        <p>Become Our Vendor</p>
                                    </div>
                                    <div className={'col-lg-4 col-md-4 qrcode_img'}>
                                        <p> For Android</p>
                                        <ModalImage
                                            small={Androidqr}
                                            large={Androidqr}
                                            alt="Hello World!"
                                            hideDownload={true}
                                            className={'an_qr'}

                                        />
                                        <a href='https://play.google.com/store/apps/details?id=com.homeeuser'> <button><FaGooglePlay /> Download</button></a>

                                    </div>
                                    <div className={'col-lg-4 col-md-4 qrcode_img'}>
                                        <p> For Ios</p>
                                        <ModalImage
                                            small={Iosqr}
                                            large={Iosqr}
                                            alt="Hello World!"
                                            hideDownload={true}
                                            className={'ios_qr'}


                                        />
                                        <a href='https://apps.apple.com/in/app/homee/id1603826750'><button><FaApple /> Download</button> </a>
                                    </div>

                                </div>
                            </div>
                            <div className={'col-lg-12 my-3 '}>
                                <div className={'inquiries'}>
                                    <center> <p>For inquiries </p> </center>

                                    <div className={'inquiries'}>
                                        <p>
                                            <a href='https://www.instagram.com/homeeotp/'> <span className={'mx-1'}><FaPhoneAlt /></span><b style={{ color: '#fff', fontSize: '17px' }}>(+91) 91508 39996 </b></a>
                                            <a href='https://www.facebook.com/homeeotp'><span className={'mx-1'} ><IoIosMail /></span> <b style={{ color: '#fff', fontSize: '17px' }}>info@homeeplatform.com</b> </a>

                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div className={'col-xl-3 col-lg-6'}>
                        <Form className={'all_form'}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className={'flable'}>Name <span>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter your name" required size='lg' />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className={'flable'}>Phone Number<span>*</span></Form.Label>
                                <Form.Control type="Number" placeholder="Enter your phone number" required size='lg' />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className={'flable'}>Email Address <span>*</span></Form.Label>
                                <Form.Control type="email" placeholder="Enter your email address" required size='lg' />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className={'flable'}>Message</Form.Label>
                                <Form.Control as="textarea" placeholder="Enter your message" rows={3} size='lg' />
                            </Form.Group>

                            <button type="submit">
                                Submit
                            </button>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;